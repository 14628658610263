<template>
    <section>
        <modal ref="modalVerLeecheros" titulo="Leecheros con mayor comisión" tamano="modal-lg" :no-aceptar="true">
            <div v-for="(leechero,p) in leecheros" :key="p" class="row mx-0 mt-2 px-0 justify-center">
                <div class="col-8">
                    <div class="row mx-0 my-2">
                        <p class="col pl-0 f-15 f-600 text-general">
                            {{ leechero.nombre }}
                        </p>
                        <p class="col text-general2">
                            <i class="icon-cedis text-general f-18" />
                            {{ leechero.nombre_cedis }}
                        </p>
                    </div>
                    <div class="row mx-0">
                        <div class="col pl-0 f-15 text-general2">
                            {{ separadorNumero(leechero.pagos) }}
                        </div>
                        <!-- <div class="col ml-auto f-15 text-general2">
                            Pedidos {{ agregarSeparadoresNumero(leechero.pedidos) }}
                        </div> -->
                    </div>
                    <div class="row mx-0 d-middle" style="height:10px;">
                        <div class="col h-100 pl-0 pr-1">
                            <div class="h-100 bg-gris br-5" :style="`width:${leechero.porcentaje_max}%`" />
                        </div>
                        <div class="col-auto px-1 text-general f-600">
                            {{ leechero.porcentaje }} %
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>

import Comisiones from "~/services/comisiones";
export default {
    data(){
        return {
            leecheros: []
        }
    },
    methods: {
        toggle(params){
            this.ver_mas_leecheros(params)
            this.$refs.modalVerLeecheros.toggle()
        },
        async ver_mas_leecheros(params){
            try {

                this.leecheros = []
                const { data } = await Comisiones.ver_mas_leecheros(params)
                console.log(data);
                this.leecheros = data.data.leecheros
            } catch (error){
                this.error_catch(e)
            }
        }
    }
}
</script>
